<template>
  <div style="position: relative; bottom: 20px">
    <transition-group name="fade">
      <div v-if="show" key="Aluno_Meus_Talentos">
        <div v-if="getUserDatas.profile_id === 5">
          <div class="row mb-5">
            <div class="h2 col-md-12">
              <h2 class="text-left mt-5 ml-5 pb-2 title-dados">Conquistas</h2>
            </div>
            <div class="row px-4 justify-content-center">
              <div class="col-md-4 col-lg-3 pb-5" v-for="(conquista, index) in conquistas" :key="index">
                <div :class="{ opacity: conquista.conquistado === false }">
                  <img :src="require(`@/assets/medalhas/${conquista.image}`)" style="width: 150px" alt="">
                </div>
                <div>
                  <p>{{ conquista.conquista }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import meusDadosComponents from "../components/meusDadosComponent";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      conquistas: [],
      show: false,
    };
  },
  components: {
    meusDadosComponents,
  },
  methods: {
    async getConquistas() {
      const response = await fetch(`${this.VUE_APP_CMS}api/conquistas`, {
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
      });
      const responseJSON = await response.json();
      this.conquistas = responseJSON
    },
  },
  mounted() {
    this.show = true;
     if (this.getUserDatas.profile_id === 5) {
      this.getConquistas();
    }
  },
  computed: {
    ...mapGetters(["getUserDatas"]),
  },
};
</script>

<style scoped>
  .opacity {
    opacity: 0.5 !important;
    filter: grayscale(0.9);
  }
</style>
